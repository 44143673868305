import React, { useState, useEffect } from "react";
import { FaTag, FaHistory } from "react-icons/fa";
import "./index.css";
import { FcOk } from "react-icons/fc";
import ModalIncluirNovo from '../ModalIncluirNovo/modal'

interface CardProps {
  osNumber: string;
  openingDate: string; // Data no formato ISO
  department: string;
  situacao: string; // Valor que define a situação
  approval: any;
  abrirModal: any;
  obj: any;
}

const statusData: { [key: string]: { color: string; description: string } } = {
  "20": { color: "#FFA500", description: "Em Preparação" },
  "30": { color: "#FFD700", description: "Aguardando Sincronização" },
  "40": { color: "#008B8B", description: "Em Campo" },
  "50": { color: "#32CD32", description: "Concluída" },
  "60": { color: "#4f4f4f", description: "Cancelada" },
  "70": { color: "#8B4513", description: "Pausa Temporária" },
  "80": { color: "#00BFFF", description: "Iniciada" },
};

// Função para formatar a data
const formatDateTime = (isoString: string): string => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Janeiro é 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const Card: React.FC<CardProps> = ({
  osNumber,
  openingDate,
  department,
  situacao,
  approval,
  abrirModal,
  obj
}) => {
  const { color: statusColor, description: statusDescription } =
    statusData[situacao] || { color: "#CCCCCC", description: "Status Desconhecido" };

function abrirmodal(){

  const novoObj = {
    ...obj, // Mantém as propriedades existentes
    statusDescription, // Adiciona ou sobrescreve a propriedade statusDescription
  };

  abrirModal(novoObj)

}

  return (
    <div
      className="card-task-principal"
      onClick={abrirmodal}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          padding: "6px",
          paddingLeft: "12px",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px", paddingTop: "5px" }}>
          <FaTag style={{ color: statusColor, fontSize: "16px" }} />
          <h3 className="status-lab">
            {statusDescription.toUpperCase()}
          </h3>
        </div>
        {/* Substituindo clip-path por SVG */}
        <svg
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "30px",
            height: "30px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <polygon points="30,0 30,30 0,0" fill={statusColor} />
        </svg>
      </div>

      {/* Body */}
      <div className="responsive-container">
        <div className="container-cad1">
          <div id="content1">
            <div className="form-group">
              <label className="card-inf-lab">OS: {osNumber}</label>
            </div>
          </div>
          <div id="content2">
            <div className="form-group">
              <label className="card-inf-lab">
                Data de Abertura: {formatDateTime(openingDate)}
              </label>
            </div>
          </div>
        </div>

        <div className="card-inf-lab-div">
          <div className="card-inf-lab-div1">
            <label className="card-inf-lab-desc">Local / Ativo: {department}</label>
          </div>
          <div className="card-inf-lab-div2">
            {approval === 1 ? (
              <FcOk style={{ fontSize: "16px" }} />
            ) : (approval === 0 || approval === null ||  approval === undefined ) && String(situacao) === "50"? (
              <FaHistory style={{ fontSize: "16px", color: "#898989" }} />
            ) : null}
        
          </div>

        </div>
      </div>



    </div>
  );
};

export default Card;
