import React, { useState, useEffect } from "react";
import "./index.css";
import { FaTools } from "react-icons/fa";
import QRCodeScanner from "../../components/Qrcode";
import Header from "../../components/Header";
import Select from 'react-select'
import ModalAlert from '../../components/ModalAlert/modal'
import { FaRegCircleXmark } from "react-icons/fa6";
import Modal from "react-modal";
import { MdQrCodeScanner } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import secureLocalStorage from "react-secure-storage";
import { FcFilledFilter } from "react-icons/fc";
import { FaFilter } from "react-icons/fa"; // FaFilterSlash é um exemplo, você pode escolher outro ícone
import { MdFilterAltOff } from "react-icons/md";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { isMobile } from "react-device-detect";
import api from '../../Services/api'
import { AxiosResponse } from "axios";
import Loading from '../../components/Loading/index'
import axios from 'axios';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { FaCheckCircle } from "react-icons/fa";

const Chamados: React.FC = () => {

  const navigate = useNavigate();

  const [empresa, setEmpresa] = useState('1');
  const [solicitante, setSolicitante] = useState('');
  const [telefone, setTelefone] = useState('');
  const [ativo, setAtivo] = useState('');
  const [observacao, setObservacao] = useState('');
  const [local, setLocal] = useState('');
  const [contrato, setContrato] = useState('');
  const [unidade, setUnidade] = useState('');
  const [andar, setAndar] = useState('');
  const [entidade, setEntidade] = useState('');
  const [tipoSolicitacao, setTipoSolicitacao] = useState('');

  const [optionTipoSolicitacao, setOptionTipoSolicitacao] = useState([
    { value: '2', label: 'Corretiva' },
  ]);

  //    { value: '3', label: 'Movimentação' },

  const [optionLocal, setOptionLocal] = useState([]);
  const [optionLocalComp, setOptionLocalComp] = useState([]);
  const [optionLocalCompleto, setOptionLocalCompleto] = useState([]);
  const [optionContrato, setOptionContrato] = useState([]);
  const [optionUnidade, setOptionUnidade] = useState([]);
  const [optionAndar, setOptionAndar] = useState([]);

  const [optionAtivo, setOptionAtivo] = useState([]);
  const [optionAtivoComp, setOptionAtivoComp] = useState([]);
  const [optionAtivoCompleto, setOptionAtivoCompleto] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [openModalFilter, setOpenModalFilter] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [openModalIncluirNovo2, setOpenModalIncluirNovo2] = useState(false)
  const [qualTitulo, setQualTitulo] = useState('')
  const [qrCodeValue, setQrCodeValue] = useState("");

  const [isDisabledContrato, setIsDisabledContrato] = useState(false)

  const optionEmpresa = [{ value: '1', label: 'Equipe Interna' }]
  const [filtroAtivo, setFiltroAtivo] = useState(false);
  const [filtroAtivo2, setFiltroAtivo2] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [imagePreviewUrl2, setImagePreviewUrl2] = useState<string | null>(null);


  useEffect(() => {


    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    setSolicitante(secureLocalStorage.getItem('n') as '')

    if (secureLocalStorage.getItem('paramsEntidade') === 'AST') {

      
      setEntidade('2')
      setTabIndex(1)

    } else if (secureLocalStorage.getItem('paramsEntidade') === 'LOC') {

      setTabIndex(0)
      setEntidade('1')
      setLocal(String(secureLocalStorage.getItem('https') || ''))


    } else {

      setEntidade('1')
      setTabIndex(0)
    }

    validatToken();

  }, []);


  async function validatToken() {

    let ret: AxiosResponse;
    let valor: any;

    const _user = secureLocalStorage.getItem('tpcm')

    api.defaults.headers.common['Authorization'] = _user as ''

    try {

      ret = await api.get("/status/validar")

      if (ret.data.status === 200) {


        await GetLocal();
        await GetContrato();
        await GetAtivo();

        setOpenModal(false)

      } else {
        setOpenModal(false)
      }

    } catch (e: any) {

      if (e.response.status === 403) {

        setQualAlert(<div className='div-alert'>
           <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
          <div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
        </div>)


      } else if (e.response === undefined) {
        setQualAlert(<div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
          <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)
      }
    }

  }

  function fecharModalToken() {
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('tpcm')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    secureLocalStorage.removeItem('https')
    navigate('/login');
    setOpenModal(false)
  }

  async function GetAtivo() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("asset/exibir")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.ast_id), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
        });


        const url = String(secureLocalStorage.getItem('https'));
        const updatedUrl = url.replace(/^https:/, 'http:');
   
        if (updatedUrl !== null && secureLocalStorage.getItem('paramsEntidade') === 'AST') {
          const qualAtv = valor.filter(
            (e: any) => {
              return e.ast_alternativeidentifier === updatedUrl
            }
          );

          setAtivo(String(qualAtv[0].ast_id))

        }
        

        setOptionAtivo(arr)
        setOptionAtivoComp(arr)
        setOptionAtivoCompleto(valor)
        
      }
    } catch (e) {
      setOptionAtivo([])
      setOptionAtivoComp([])
    }
  }

  async function GetContrato() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("contract/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.ctc_id), label: user.ctc_description });
        });

        setOptionContrato(arr)


        if (arr.length === 1) {
          setIsDisabledContrato(true)
          setContrato(String(arr[0].value))
          GetUnidade2(String(arr[0].value))
        }

      } else {
        setOptionContrato([])
      }
    } catch (e) {
      setOptionContrato([])
    }
  }

  function IncluirContrato(id: any) {
    if (id === '') {
      setOptionUnidade([]);
      setOptionAndar([]);
      setContrato('');
      setUnidade('');
      setAndar('');
    } else {
      setUnidade('');
      GetUnidade(id);
      setContrato(id);
    }
  }

  async function GetUnidade2(id: any) {

    let ret: AxiosResponse;
    let valor: any;

    try {
      ret = await api.get("unity/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.unt_id), label: user.unt_description });
        });

        setOptionUnidade(arr)

      } else {

        setOptionUnidade([]);

      }
    } catch (e) {
      setOptionUnidade([]);

    }
  }

  async function GetUnidade(id: any) {

    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {
      ret = await api.get("unity/listar", {
        params: {
          id: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.unt_id), label: user.unt_description });
        });

        setOptionUnidade(arr)
        setOpenModal(false)
      } else {

        setOptionUnidade([]);
        setOpenModal(false)
      }
    } catch (e) {
      setOptionUnidade([]);
      setOpenModal(false)
    }
  }

  function IncluirUnidade(id: any) {
    if (id === '') {
      setOptionAndar([]);
      setUnidade('');
      setAndar('');
    } else {
      setAndar('');
      GetPavimento(id);
      setUnidade(id);
    }
  }

  async function GetPavimento(unidade: any) {
    let ret: AxiosResponse;
    let valor: any;
    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)
    try {
      ret = await api.get("local/listar", {
        params: {
          id: unidade
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_pavement), label: user.loc_pavement });
        });
        setOpenModal(false)
        setOptionAndar(arr)

      } else {

        setOpenModal(false)

      }
    } catch (e) {
      setOpenModal(false)
      setOptionAndar([])
    }
  }

  async function GetLocal() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("local/listarlocal")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;

        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description + " (" + user.loc_alternativeidentifier + ")" });
        });

        setOptionLocal(arr)
        setOptionLocalComp(arr)
        setOptionLocalCompleto(valor)

        const url = String(secureLocalStorage.getItem('https'));

  
        if (secureLocalStorage.getItem('paramsEntidade') === 'LOC') {
          const qualAtv = valor.filter(
            (e: any) => {
              return e.loc_alternativeidentifier === url
            }
          );

          setLocal(String(qualAtv[0].loc_id))

        }


      } else {
        setOptionLocalComp([])
        setOptionLocal([])
      }
    } catch (e) {
      setOptionLocal([])
    }
  }

  function incluirLocal(id: any) {

    if (id === '') {
      setLocal('')
      setOptionLocal(optionLocalComp)
    } else {
      setLocal(id)
    }

  }

  async function GetLocalAndar() {
    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (contrato === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o contrato.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (unidade === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o unidade.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (andar === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o andar.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else {

      try {
        ret = await api.get("local/listarlocpaviment", {
          params: {
            id: unidade,
            paviment: andar
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;

          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.loc_id), label: user.loc_description + " (" + user.loc_alternativeidentifier + ")" });
          });
          setOptionLocal(arr)
          setFiltroAtivo(true)
          setOpenModalIncluirNovo(false)
          setOpenModal(false)
        }
      } catch (e) {
        setOpenModal(false)
        setOpenModalIncluirNovo(false)
        setOptionLocal([])
        setFiltroAtivo(true)
      }

    }

  }

  const handleOpenModal = () => {
    if (isMobile) {

      secureLocalStorage.removeItem('paramsEntidade')
      secureLocalStorage.removeItem('paramsQrcode')
      secureLocalStorage.removeItem('https')
      setIsModalOpen(true);
      setQualTitulo('Escanear QR Code')

    } else {

      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Este recurso está disponível apenas em dispositivos móveis.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);

    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const uploadImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const extensoesPermitidas = /\.(jpg|jpeg|png|gif)$/i;

    if (file) {
      if (extensoesPermitidas.test(file.name)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        e.target.value = '';
        setQualAlert(
          <div className="div-alert">
            <FaRegCircleXmark className="ControleIconAlertcancel" />
            <div className="div-info-alert-atencao">
              <span className="info-alert-atencao">ATENÇÃO</span>
            </div>
            <div className="div-info-alert">
              <span className="info-alert">
                Apenas arquivos JPG, JPEG, PNG e GIF são permitidos.
              </span>
            </div>
            <div className="btn-alert" onClick={fecharModal2}>
              <span className="btn-alert-lab">OK</span>
            </div>
          </div>
        );
        setOpenModal(true);
      }
    }
  };



  const uploadImg2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const extensoesPermitidas = /\.(jpg|jpeg|png|gif)$/i;

    if (file) {
      if (extensoesPermitidas.test(file.name)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl2(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        e.target.value = '';
        setQualAlert(
          <div className="div-alert">
            <FaRegCircleXmark className="ControleIconAlertcancel" />
            <div className="div-info-alert-atencao">
              <span className="info-alert-atencao">ATENÇÃO</span>
            </div>
            <div className="div-info-alert">
              <span className="info-alert">
                Apenas arquivos JPG, JPEG, PNG e GIF são permitidos.
              </span>
            </div>
            <div className="btn-alert" onClick={fecharModal2}>
              <span className="btn-alert-lab">OK</span>
            </div>
          </div>
        );
        setOpenModal(true);
      }
    }
  };

  function fecharModal2() {
    setOpenModal(false)
  }


  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '35px',
      height: '35px',
      border: state.isFocused ? '1px solid #000000' : '1px solid #92989e', // Ajusta borda para 1px no foco
      boxShadow: 'none', // Remove qualquer sombra adicional no foco
      '&:hover': {
        borderColor: state.isFocused ? '#000000' : 'grey', // Cor da borda no hover
      },
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
      padding: '0 6px',

    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
    }),
  };

  function finalizarModal2() {
    setOpenModal(false)
  }


  function base64ToFile(base64Data: any, fileName: any) {
    const byteCharacters = atob(base64Data.split(',')[1]); // Decodifica a parte base64
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Obtém o tipo de conteúdo
    const contentType = base64Data.split(';')[0].split(':')[1];

    // Cria um novo File a partir do Blob
    return new File([new Blob([byteNumbers], { type: contentType })], fileName, {
      type: contentType,
    });
  }

  async function Confirmar(event: React.FormEvent) {

    event.preventDefault(); // Impede a atualização da página

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (empresa === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o responsável por executar esta demanda.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (solicitante === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Insira o seu nome.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (local === '' && entidade === '1') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (ativo === '' && entidade === '2') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o ativo.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (entidade === '2' && tipoSolicitacao === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o tipo de solicitação.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );
      setOpenModal(true);
    } else if (entidade === '2' && tipoSolicitacao === '3' && local === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o local de destino.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );
      setOpenModal(true);
    } else if (observacao === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Por favor, descreva os detalhes do chamado que deseja abrir. Esse campo é obrigatório para prosseguir.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else {


      if ((imagePreviewUrl !== null && imagePreviewUrl !== "") ||
        (imagePreviewUrl2 !== null && imagePreviewUrl2 !== "")) {

        const formData = new FormData();

        const endPoint = secureLocalStorage.getItem('endPoint')

        const ambiente = String(secureLocalStorage.getItem('tp') || '')
          .toLowerCase()
          .replace(/\s+/g, '');

        const ambienteString = typeof ambiente === 'string'
          ? ambiente.toLowerCase()
          : String(ambiente).toLowerCase();

        formData.append('ambiente', ambienteString);

        if (imagePreviewUrl) {
          const fileName = `${Date.now()}.png`; // Nome do arquivo com timestamp
          const file = base64ToFile(imagePreviewUrl, fileName); // Converte para File

          formData.append('files', file); // Adiciona o File ao FormData
        }

        if (imagePreviewUrl2) {
          const fileName = `${Date.now()}2.png`; // Nome do arquivo com timestamp
          const file = base64ToFile(imagePreviewUrl2, fileName); // Corrige para usar imagePreviewUrl2

          formData.append('files', file); // Adiciona o File ao FormData
        }


        const _user = secureLocalStorage.getItem('tpcm');

        api.defaults.headers.common['Authorization'] = _user as ''


        axios({
          method: "post",
          url: endPoint + 'upload',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        }).then(async function (response: any) {


          const newUuid = uuidv4()
  

          let tipoSil = null

          if (entidade === '1') {
            tipoSil = '2'
          } else {
            tipoSil = tipoSolicitacao
          }


          const now = new Date();
          const offset = -3; // Horário de Brasília (UTC-3)
          const brazilDate = new Date(now.getTime() + offset * 60 * 60 * 1000);
          
          // Formata para o padrão desejado
          const year = brazilDate.getUTCFullYear();
          const month = String(brazilDate.getUTCMonth() + 1).padStart(2, "0"); // `getUTCMonth` retorna de 0 a 11
          const day = String(brazilDate.getUTCDate()).padStart(2, "0");
          const hours = String(brazilDate.getUTCHours()).padStart(2, "0");
          const minutes = String(brazilDate.getUTCMinutes()).padStart(2, "0");
          const seconds = String(brazilDate.getUTCSeconds()).padStart(2, "0");
          
          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

          const dados = [{
            tsk_active: 1,
            tsk_recreate: 0,
            tsk_notification: 1,
            tty_id: tipoSil,
            age_id: null,
            tea_id: empresa,  //precisa ser ajustado para outras empresas  //antes tava empresa
            tsf_id: 1,
            ast_id: ativo || null,
            tsk_integrationid: null,
            stn_id: 30,
            tsk_priority: null,
            src_id: 5,
            tsk_observation: observacao,
            tsk_responsible: secureLocalStorage.getItem('n'),
            tsk_requester: solicitante,
            tsk_telephone: telefone,
            tsk_agendadescription: null,
            agd_id: null,
            tsk_callback: null,
            tsk_frequency: null,
            crt_id: null,
            pvt_id: null,
            tsk_technicalinstruction: null,
            tsk_image1: response?.data?.results?.[0]?.link || null,
            tsk_image2: response?.data?.results?.[1]?.link || null,
            ivb_id: null,
            tsk_scheduleinitialdatehour: formattedDate, //now.getTime() - now.getTimezoneOffset() * 60000
            tsk_schedulefinaldatehour: null,
            tsk_waitingtime: null,
            ctc_id: null,
            unt_id: null,
            loc_id: local || null,
            tsk_userdestination: null,
            age_id_insert: secureLocalStorage.getItem('iag'),
            ett_id: entidade,
            tsk_accesstoken: newUuid
          }]


          let ret: AxiosResponse;
          let dadosComp = [] as any

          const _user = secureLocalStorage.getItem('tpcm');

          api.defaults.headers.common['Authorization'] = _user as ''


          try {
            ret = await api.post("task/insert", {
              data: dados,
              dadosComp: dadosComp
            });
            if (ret.data.status === 200) {

              setAtivo("")
              setTipoSolicitacao("")
              setLocal('')
              setObservacao('')
              limparAnexos()
              setFiltroAtivo(false)

              setQualAlert(<div className='div-alert'>
                <FaCheckCircle className="ControleIconAlert" />
                <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                <div className='div-info-alert'><span className="info-alert">Tarefa criada com sucesso.</span></div>
                <div className='btn-alert' onClick={finalizarModal3}><span className="btn-alert-lab">OK</span></div>
              </div>)
              setOpenModal(true)
            }

          } catch (e: any) {
            setQualAlert(<div className='div-alert'>
              <FaRegCircleXmark className="ControleIconAlertcancel" />
              <div className='div-info-alert'><span className="info-alert">Não é possível criar a Tarefa.</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
          }





        })
          .catch(async function (error) {

            if (error.response === undefined) {
              setQualAlert(<div className='div-alert'>
                <FaRegCircleXmark className="ControleIconAlertcancel" />
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Sem comunicação com o servidor.</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
              </div>)

              setOpenModal(true)
            } else
              if (error.response.status === 403) {

                setQualAlert(<div className='div-alert'>
                  <FaRegCircleXmark className="ControleIconAlertcancel" />
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado.</span></div>
                  <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

              } else {
                setQualAlert(<div className='div-alert'>
                  <FaRegCircleXmark className="ControleIconAlertcancel" />
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Não foi possível concluir sua solicitação.</span></div>
                  <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
              }

          });


      } else {

        let tipoSil = null

        if (entidade === '1') {
          tipoSil = '2'
        } else {
          tipoSil = tipoSolicitacao
        }

        const newUuid = uuidv4()
    

        const now = new Date();
        const offset = -3; // Horário de Brasília (UTC-3)
        const brazilDate = new Date(now.getTime() + offset * 60 * 60 * 1000);
        
        // Formata para o padrão desejado
        const year = brazilDate.getUTCFullYear();
        const month = String(brazilDate.getUTCMonth() + 1).padStart(2, "0"); // `getUTCMonth` retorna de 0 a 11
        const day = String(brazilDate.getUTCDate()).padStart(2, "0");
        const hours = String(brazilDate.getUTCHours()).padStart(2, "0");
        const minutes = String(brazilDate.getUTCMinutes()).padStart(2, "0");
        const seconds = String(brazilDate.getUTCSeconds()).padStart(2, "0");
        
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        const dados = [{
          tsk_active: 1,
          tsk_recreate: 0,
          tsk_notification: 1,
          tty_id: tipoSil,
          age_id: null,
          tea_id: empresa,  //precisa ser ajustado para outras empresas 
          tsf_id: 1,
          ast_id: ativo || null,
          tsk_integrationid: null,
          stn_id: 30,
          tsk_priority: null,
          src_id: 5,
          tsk_observation: observacao,
          tsk_responsible: secureLocalStorage.getItem('n'),
          tsk_requester: solicitante,
          tsk_telephone: telefone,
          tsk_agendadescription: null,
          agd_id: null,
          tsk_callback: null,
          tsk_frequency: null,
          crt_id: null,
          pvt_id: null,
          tsk_technicalinstruction: null,
          tsk_image1: null,
          tsk_image2: null,
          ivb_id: null,
          tsk_scheduleinitialdatehour: formattedDate, //now.getTime() - now.getTimezoneOffset() * 60000
          tsk_schedulefinaldatehour: null,
          tsk_waitingtime: null,
          ctc_id: null,
          unt_id: null,
          loc_id: local || null,
          tsk_userdestination: null,
          age_id_insert: secureLocalStorage.getItem('iag'),
          ett_id: entidade,
          tsk_accesstoken: newUuid
        }]

        const _user = secureLocalStorage.getItem('tpcm');

        api.defaults.headers.common['Authorization'] = _user as ''

        let ret: AxiosResponse;
        let dadosComp = [] as any

        try {
          ret = await api.post("task/insert", {
            data: dados,
            dadosComp: dadosComp
          });
          if (ret.data.status === 200) {

            setAtivo("")
            setTipoSolicitacao("")
            setLocal('')
            setObservacao('')
            limparAnexos()
            setFiltroAtivo(false)

            setQualAlert(<div className='div-alert'>
              <FaCheckCircle className="ControleIconAlert" />
              <span className="fa-regular fa-circle-check ControleIconAlert"></span>
              <div className='div-info-alert'><span className="info-alert">Tarefa criada com sucesso.</span></div>
              <div className='btn-alert' onClick={finalizarModal3}><span className="btn-alert-lab">OK</span></div>
            </div>)
            setOpenModal(true)
          }

        } catch (e: any) {

          setQualAlert(<div className='div-alert'>
            <FaRegCircleXmark className="ControleIconAlertcancel" />
            <div className='div-info-alert'><span className="info-alert">Não é possível criar a Tarefa.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>)
          setOpenModal(true)
        }

      }

    }
  }

  function finalizarModal3() {
    secureLocalStorage.removeItem('https')
    window.location.reload();
  }



  function fecharModal() {

    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('tpcm')
    secureLocalStorage.removeItem('acp')
    secureLocalStorage.removeItem('n')
    secureLocalStorage.removeItem('iag')
    navigate('/login');
    setOpenModal(false)

  }

  function ChamarAtivo() {
    setEntidade('2')
    setOptionLocal(optionLocalComp)
    setOptionAtivo(optionAtivoComp)
    setLocal('')
    setAtivo('')
    setObservacao('')
    limparAnexos()
    setFiltroAtivo(false)
    setFiltroAtivo2(false)
    setTabIndex(1)
    setTipoSolicitacao("")
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('https')
  }

  function ChamarLocal() {
    setEntidade('1')
    setOptionAtivo(optionAtivoComp)
    setOptionLocal(optionLocalComp)
    setLocal('')
    setTipoSolicitacao("")
    setAtivo('')
    setObservacao('')
    limparAnexos()
    setFiltroAtivo(false)
    setFiltroAtivo2(false)
    setTabIndex(0)
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('https')
  }

  function abrirModalFiltro() {
    setOptionAndar([])
    setOptionLocal(optionLocalComp)
    setUnidade('');
    setAndar('');
    setLocal('')
    setQualTitulo('Filtro Avançado')
    setOpenModalIncluirNovo(true)
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('https')
  }

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  const handleQrCodeDetected = (value: any) => {
    setIsModalOpen(false)
    setQrCodeValue(value);
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('https')

    if(entidade === '1'){

      if (optionLocalCompleto.length > 0) {
        const qualAtv = optionLocalCompleto.filter(
          (e) => (e as any).loc_alternativeidentifier === value
        );
      
        if (qualAtv.length > 0) {
          setLocal(String((qualAtv[0] as any).loc_id));
        } else {
          setQualAlert(
            <div className='div-alert'>
              <FaRegCircleXmark className="ControleIconAlertcancel" />
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Local não encontrado.</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>
          );
    
          setOpenModal(true);
        }
      }
      
    }else if(entidade === '2'){

      if (optionAtivoCompleto.length > 0) {
        const qualAtv = optionAtivoCompleto.filter(
          (e) => (e as any).ast_alternativeidentifier === value
        );
      
        if (qualAtv.length > 0) {

          setAtivo(String((qualAtv[0] as any).ast_id));

        } else {
          setQualAlert(
            <div className='div-alert'>
              <FaRegCircleXmark className="ControleIconAlertcancel" />
              <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
              <div className='div-info-alert'><span className="info-alert">Ativo não encontrado.</span></div>
              <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>
          );
    
          setOpenModal(true);
        }
      }
      
    }



  };

  function alternarFiltro() {
    secureLocalStorage.removeItem('paramsEntidade')
    secureLocalStorage.removeItem('paramsQrcode')
    secureLocalStorage.removeItem('https')
    setLocal('')
    setOptionLocal(optionLocalComp)
    setFiltroAtivo(false)
  }


  function alternarFiltro2() {
    secureLocalStorage.removeItem('https')
    setAtivo('')
    setOptionAtivo(optionAtivoComp)
    setFiltroAtivo2(false)
  }

  function limparAnexos() {

    setImagePreviewUrl("")
    setImagePreviewUrl2("")
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }

    const fileInput2 = document.getElementById("fileInput2") as HTMLInputElement;
    if (fileInput2) {
      fileInput2.value = "";
    }
  }

  //--------------------filtros ativos------------------------------------------

  function abrirModalFiltroAtivo() {
    secureLocalStorage.removeItem('https')
    setOptionAndar([])
    setOptionLocal(optionLocalComp)
    setUnidade('');
    setAndar('');
    setLocal('')
    setAtivo('')
    setQualTitulo('Filtro Avançado')
    setOpenModalIncluirNovo2(true)
    GetLocalAtivo()
  }


  function fecharOpenModalIncluirNovo2() {
    setOpenModalIncluirNovo2(false)
  }


  function setarAndar(id: any) {

    if (id === '') {
      setOptionLocal(optionLocalComp)
      setAndar('');
      setLocal('');
    } else {
      setLocal('');
      setAndar(id)
      GetLocalAndarFilter(id)
    }
  }


  async function GetLocalAndarFilter(id: any) {
    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {
      ret = await api.get("local/listarlocpaviment", {
        params: {
          id: unidade,
          paviment: id
        }
      })
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;
        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });

        setOptionLocal(arr)
        setOpenModal(false)

      }
    } catch (e) {
      setOpenModal(false)
      setOptionLocal([])
    }
  }

  async function GetLocalAtivo() {
    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {
      ret = await api.get("local/listarlocal")
      if (ret.data.status === 200) {
        valor = ret.data.data;

        const arr = [] as any;
        let result = valor;

        result.map((user: any) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });

        setOpenModal(false)
        setOptionLocal(arr)

      } else {
        setOptionLocal([])
      }
    } catch (e) {
      setOpenModal(false)
      setOptionLocal([])
    }
  }

  function IncluirUnidadeAtivo(id: any) {
    if (id === '') {
      setOptionAndar([]);
      setUnidade('');
      setAndar('');
      setLocal('')
      setOptionLocal(optionLocalComp)
    } else {
      setLocal('')
      setAndar('');
      GetPavimento(id);
      setUnidade(id);
    }
  }

  async function GetAtivoLocal() {
    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (local === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else {

      try {
        ret = await api.get("local/listarativoloc", {
          params: {
            id: local
          }
        })
        if (ret.data.status === 200) {
          valor = ret.data.data;

          const arr = [] as any;
          let result = valor;
          result.map((user: any) => {
            return arr.push({ value: String(user.ast_id), label: user.ast_alternativeidentifier ? user.ast_description + " (" + user.ast_alternativeidentifier + ")" : user.ast_description });
          });

          setOptionAtivo(arr)
          setLocal('')
          setOptionLocal(optionLocalComp)
          setFiltroAtivo2(true)
          setOpenModalIncluirNovo2(false)

        }
        setOpenModal(false)
      } catch (e) {
        setFiltroAtivo2(true)
        setOpenModal(false)
        setOptionAtivo([])
      }
    }

  }

  function IncluirSolicitacao(id: any) {

    if (id === '') {
      setLocal('')
      setTipoSolicitacao("")
    } else {
      setLocal('')
      setTipoSolicitacao(id)
    }
  }

  return (
    <>
      <div className="chamados-container">
        <Header />
        <div className="chamados-main">

          <div className="header-container">
            <div className="line"></div>
            <div className="icon-circle">
              <MdQrCodeScanner className="qrcode-icon" onClick={handleOpenModal} />
            </div>
            <div className="line"></div>
          </div>

          <div className="chamados-card">

            <div className="card-tab">
              <div className="card-header">

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>

                  <div className="container-cad2">
                    <TabList>

                      <Tab onClick={ChamarLocal}>
                        <p>Local</p>
                      </Tab>


                      <Tab onClick={ChamarAtivo}>
                        <p>Ativo</p>
                      </Tab>

                    </TabList>


                  </div>

                  <div className="container-cad2">
                    <TabPanel>
                      <div className="panel-content">

                        <div className="form-chamados">
                          <form className="chamados-form">
                            <div className="form-group">
                              <label>Empresa: *</label>
                              <Select
                                maxMenuHeight={140}
                                placeholder={'Selecione...'}
                                options={optionEmpresa}
                                value={optionEmpresa.filter(function (option: any) {
                                  return option.value === empresa;
                                })}
                                onChange={(options: any) =>
                                  !options ? setEmpresa("") : setEmpresa(options.value)
                                }
                                styles={customStyles}
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                            <div className="container-cad1">
                              <div id="content1">
                                <div className="form-group">
                                  <label>Solicitante: *</label>
                                  <input type="text" placeholder="Digite seu nome"
                                    onChange={(e) => setSolicitante(e.target.value)}
                                    value={solicitante} />
                                </div>
                              </div>
                              <div id="content2">
                                <div className="form-group">
                                  <label>Telefone:</label>
                                  <input type="text"
                                    placeholder="Digite seu telefone"
                                    onChange={(e) => setTelefone(e.target.value)}
                                    value={telefone} />
                                </div>
                              </div>
                            </div>


                            <div className="form-group">
                              <label>
                                Local: *
                              </label>

                              <div className="filtro-avancado">

                                <div className="filtro-avancado-select">
                                  <Select
                                    maxMenuHeight={140}
                                    placeholder={'Selecione...'}
                                    options={optionLocal}
                                    value={optionLocal.filter(function (option: any) {
                                      return option.value === local;
                                    })}
                                    onChange={(options: any) =>
                                      !options ? incluirLocal("") : incluirLocal(options.value)
                                    }
                                    styles={customStyles}
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme: any) => ({
                                      ...theme,
                                      borderRadius: 4,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                      },
                                    })}
                                  />
                                </div>

                                {filtroAtivo ? (
                                  <div className="filtro-avancado-btn" onClick={alternarFiltro}>
                                    <MdFilterAltOff
                                      className="btn-filter-remover"
                                      onClick={alternarFiltro}
                                      title="Filtro ativo"
                                    />
                                  </div>
                                ) : (
                                  <div className="filtro-avancado-btn" onClick={abrirModalFiltro}>
                                    <FaFilter
                                      className="btn-filter"
                                      onClick={abrirModalFiltro}
                                      title="Sem filtro"
                                    />
                                  </div>
                                )}

                              </div>

                            </div>

                            <div className="form-group">
                              <label>Observação: *</label>
                              <textarea className="inp-textarea" placeholder="Digite uma observação"
                                onChange={(e) => setObservacao(e.target.value)}
                                value={observacao}
                              ></textarea>
                            </div>

                            <div className="btn-escolha">
                              <input
                                type="file"
                                id="fileInput"
                                onChange={uploadImg}
                              />
                            </div>

                            <div className="btn-escolha">
                              <input
                                type="file"
                                id="fileInput2"
                                onChange={(e) => {
                                  uploadImg2(e);
                                }}
                              />
                            </div>

                            <div className="btn-container">
                              <button type="submit" className="btn-enviar" onClick={Confirmar}>Enviar</button>
                            </div>

                          </form>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="panel-content">
                        <div className="form-chamados">
                          <form className="chamados-form">
                            <div className="form-group">
                              <label>Empresa: *</label>
                              <Select
                                maxMenuHeight={140}
                                placeholder={'Selecione...'}
                                options={optionEmpresa}
                                value={optionEmpresa.filter(function (option: any) {
                                  return option.value === empresa;
                                })}
                                onChange={(options: any) =>
                                  !options ? setEmpresa("") : setEmpresa(options.value)
                                }
                                styles={customStyles}
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>
                            <div className="container-cad1">
                              <div id="content1">
                                <div className="form-group">
                                  <label>Solicitante: *</label>
                                  <input type="text" placeholder="Digite seu nome"
                                    onChange={(e) => setSolicitante(e.target.value)}
                                    value={solicitante} />
                                </div>
                              </div>
                              <div id="content2">
                                <div className="form-group">
                                  <label>Telefone:</label>
                                  <input type="text"
                                    placeholder="Digite seu telefone"
                                    onChange={(e) => setTelefone(e.target.value)}
                                    value={telefone} />
                                </div>
                              </div>
                            </div>


                            <div className="form-group">
                              <label>
                                Ativo: *
                              </label>

                              <div className="filtro-avancado">

                                <div className="filtro-avancado-select">
                                  <Select
                                    maxMenuHeight={140}
                                    placeholder={'Selecione...'}
                                    options={optionAtivo}
                                    value={optionAtivo.filter(function (option: any) {
                                      return option.value === ativo;
                                    })}
                                    onChange={(options: any) =>
                                      !options ? setAtivo("") : setAtivo(options.value)
                                    }
                                    styles={customStyles}
                                    isClearable
                                    noOptionsMessage={() => "Não encontrado..."}
                                    theme={(theme: any) => ({
                                      ...theme,
                                      borderRadius: 4,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                      },
                                    })}
                                  />
                                </div>

                                {filtroAtivo2 ? (
                                  <div className="filtro-avancado-btn" onClick={alternarFiltro2}>
                                    <MdFilterAltOff
                                      className="btn-filter-remover"
                                      onClick={alternarFiltro2}
                                      title="Filtro ativo"
                                    />
                                  </div>
                                ) : (
                                  <div className="filtro-avancado-btn" onClick={abrirModalFiltroAtivo}>
                                    <FaFilter
                                      className="btn-filter"
                                      onClick={abrirModalFiltroAtivo}
                                      title="Sem filtro"
                                    />
                                  </div>
                                )}

                              </div>

                            </div>



                            <div className="form-group">
                              <label>Tipo de Solicitação: *</label>
                              <Select
                                maxMenuHeight={140}
                                placeholder={'Selecione...'}
                                options={optionTipoSolicitacao}
                                value={optionTipoSolicitacao.filter(function (option: any) {
                                  return option.value === tipoSolicitacao;
                                })}
                                onChange={(options: any) =>
                                  !options ? IncluirSolicitacao("") : IncluirSolicitacao(options.value)
                                }
                                styles={customStyles}
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                  ...theme,
                                  borderRadius: 4,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#ebebeb',
                                    primary: 'black',
                                  },
                                })}
                              />
                            </div>


                            {tipoSolicitacao === '3' ? (
                              <div className="form-group">
                                <label>
                                  Local de Destino: *
                                </label>
                                <div className="filtro-avancado">
                                  <div className="filtro-avancado-select">
                                    <Select
                                      maxMenuHeight={140}
                                      placeholder={'Selecione...'}
                                      options={optionLocal}
                                      value={optionLocal.filter(function (option: any) {
                                        return option.value === local;
                                      })}
                                      onChange={(options: any) =>
                                        !options ? incluirLocal("") : incluirLocal(options.value)
                                      }
                                      styles={customStyles}
                                      isClearable
                                      noOptionsMessage={() => "Não encontrado..."}
                                      theme={(theme: any) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                          ...theme.colors,
                                          primary25: '#ebebeb',
                                          primary: 'black',
                                        },
                                      })}
                                    />
                                  </div>

                                  {filtroAtivo ? (
                                    <div className="filtro-avancado-btn" onClick={alternarFiltro}>
                                      <MdFilterAltOff
                                        className="btn-filter-remover"
                                        onClick={alternarFiltro}
                                        title="Filtro ativo"
                                      />
                                    </div>
                                  ) : (
                                    <div className="filtro-avancado-btn" onClick={abrirModalFiltro}>
                                      <FaFilter
                                        className="btn-filter"
                                        onClick={abrirModalFiltro}
                                        title="Sem filtro"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (null)}

                            <div className="form-group">
                              <label>Observação: *</label>
                              <textarea className="inp-textarea" placeholder="Digite uma observação"
                                onChange={(e) => setObservacao(e.target.value)}
                                value={observacao}
                              ></textarea>
                            </div>

                            <div className="btn-escolha">
                              <input
                                type="file"
                                id="fileInput"
                                onChange={uploadImg}
                              />
                            </div>

                            <div className="btn-escolha">
                              <input
                                type="file"
                                id="fileInput2"
                                onChange={(e) => {
                                  uploadImg2(e);
                                }}
                              />
                            </div>

                            <div className="btn-container">
                              <button type="submit" className="btn-enviar" onClick={Confirmar}>Enviar</button>
                            </div>

                          </form>
                        </div>
                      </div>
                    </TabPanel>
                  </div>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAlert isOpen={isModalOpen} setModalOpen={() => handleCloseModal()}>


        <div className='title-modal-incluir'>
          <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
          <div onClick={handleCloseModal}>
            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>

        <div className='container-modal-alert'>
          <QRCodeScanner
            modalOpen={isModalOpen}
            onQrCodeDetected={handleQrCodeDetected}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      </ModalAlert>


      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className='scrol-modal'>
            <div className="form-group-modal">
              <label>Contrato: *</label>
              <Select
                maxMenuHeight={140}
                placeholder={'Selecione...'}
                options={optionContrato}
                isDisabled={isDisabledContrato}
                value={optionContrato.filter(function (option: any) {
                  return option.value === contrato;
                })}
                onChange={(options: any) =>
                  !options ? IncluirContrato("") : IncluirContrato(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>




            <div className="form-group-modal">
              <label>Unidade: *</label>
              <Select
                maxMenuHeight={140}
                placeholder={'Selecione...'}
                options={optionUnidade}
                value={optionUnidade.filter(function (option: any) {
                  return option.value === unidade;
                })}
                onChange={(options: any) =>
                  !options ? IncluirUnidade("") : IncluirUnidade(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>




            <div className="form-group-modal">
              <label>Andar: *</label>
              <Select
                maxMenuHeight={90}
                placeholder={'Selecione...'}
                options={optionAndar}
                value={optionAndar.filter(function (option: any) {
                  return option.value === andar;
                })}
                onChange={(options: any) =>
                  !options ? setAndar("") : setAndar(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>

            <div className="btn-container">
              <button type="submit" className="btn-enviar" onClick={GetLocalAndar}>Filtrar</button>
            </div>

          </div>

        </>
      </ModalIncluirNovo>

      <ModalIncluirNovo isOpen={openModalIncluirNovo2} setModalOpen={() => setOpenModalIncluirNovo2(!openModalIncluirNovo2)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{qualTitulo}</h1>
            <div onClick={fecharOpenModalIncluirNovo2}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>

          <div className='scrol-modal'>
            <div className="form-group-modal">
              <label>Contrato: </label>
              <Select
                maxMenuHeight={140}
                placeholder={'Selecione...'}
                options={optionContrato}
                isDisabled={isDisabledContrato}
                value={optionContrato.filter(function (option: any) {
                  return option.value === contrato;
                })}
                onChange={(options: any) =>
                  !options ? IncluirContrato("") : IncluirContrato(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>

            <div className="form-group-modal">
              <label>Unidade: </label>
              <Select
                maxMenuHeight={140}
                placeholder={'Selecione...'}
                options={optionUnidade}
                value={optionUnidade.filter(function (option: any) {
                  return option.value === unidade;
                })}
                onChange={(options: any) =>
                  !options ? IncluirUnidadeAtivo("") : IncluirUnidadeAtivo(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>

            <div className="form-group-modal">
              <label>Andar: </label>
              <Select
                maxMenuHeight={90}
                placeholder={'Selecione...'}
                options={optionAndar}
                value={optionAndar.filter(function (option: any) {
                  return option.value === andar;
                })}
                onChange={(options: any) =>
                  !options ? setarAndar("") : setarAndar(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>

            <div className="form-group-modal">
              <label>Local: *</label>
              <Select
                maxMenuHeight={90}
                placeholder={'Selecione...'}
                options={optionLocal}
                value={optionLocal.filter(function (option: any) {
                  return option.value === local;
                })}
                onChange={(options: any) =>
                  !options ? setLocal("") : setLocal(options.value)
                }
                styles={customStyles}
                isClearable
                noOptionsMessage={() => "Não encontrado..."}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: '#ebebeb',
                    primary: 'black',
                  },
                })}
              />
            </div>

            <div className="btn-container">
              <button type="submit" className="btn-enviar" onClick={GetAtivoLocal}>Filtrar</button>
            </div>

          </div>

        </>
      </ModalIncluirNovo>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );
};

export default Chamados;
