import { createGlobalStyle } from "styled-components";
import 'font-awesome/css/font-awesome.min.css';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing:border-box;
    }

    html, body, #root{
        height: 100%;
        background: linear-gradient(180deg, #0076BE, #00304E);
        margin: 0;
        padding: 0;
    }
    *, button, input{
       border: 0;
       outline: 0;
       font-family: arial;
    }

    button{
        cursor:pointer;
    }

    #nav-toggle:checked ~ .main-content .DivGrafico{
      transition: margin-left 300ms;
      padding-left: 16%;
    }



  main {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.2);
  }


  .container-card-inicial {
    margin-top: 12px;

  }

  .containerPrincipal{
    padding: 1.5rem 1.5rem;
  }


#nav-toggle:checked ~ .main-content {
  margin-left: 70px;
}

.main-content {
  transition: margin-left 300ms;
  margin-left: 200px;
  padding: 10px;
}

#nav-toggle:checked + .sidebar {
  width: 70px;
}

#nav-toggle:checked + .sidebar .side:hover .Legenda-side {
  display: inline;
}

#nav-toggle:checked + .sidebar .sidebar-brand .logimg {
  display: none;
  background-color: red;
}


#nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown {
  display: inline;
}

#nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown a {
  display: inline;
  list-style: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-left: -10px;
  padding: 5px;
}

#nav-toggle:checked + .sidebar .side-dropdown:hover .lab-Dropdown {
  font-size: 10px;
  display: inline;
  color: #fff
}

#nav-toggle:checked + .sidebar .lab-Dropdown:hover {
  color: #fff
}
#nav-toggle:checked + .sidebar  .ControleIcon-Dropdown:hover{
  color: #fff
}

#nav-toggle:checked + .sidebar i {
  display: none;
}

#nav-toggle:checked + .sidebar .lab-icone {
  display: none;
}

#nav-toggle:checked + .sidebar .lab-icone2 {
  display: none;
}

#nav-toggle:checked + .sidebar .nav__dropdown-content {
  display: none;
}

#nav-toggle:checked + .sidebar .ControleIcon:hover {
  -moz-transform: scale(2.2);
  -webkit-transform: scale(5.5);
  transform: scale(1.2);
}

#nav-toggle:checked + .sidebar li a {
  padding-left: 0rem;
  font-size: 20px;
}

#nav-toggle:checked + .Icon-drop {
  display: none;
}

#nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
#nav-toggle:checked + .sidebar li a span:last-child {
  display: none;
}

#nav-toggle:checked ~ .main-content header {
  width: calc(100% - 70px);
  left: 70px;
  transition: left 300ms;
}

#nav-toggle:checked + .sidebar .sidebar-brand  .logoNav {
  display: inline;
  }

@media only screen and (max-width: 768px) {


  .containerPrincipal{
    padding: 1rem 1rem;
  }

  #nav-toggle:checked ~ .main-content .DivGrafico{
    padding-left: 16%;
  }

  main {
    margin-top: 20px;
  }



  #nav-toggle:checked + .sidebar .nav__dropdown-content {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .lab-icone {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .lab-icone2 {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .dropdown-background {
    display: inline;
  }

  #nav-toggle:checked + .sidebar i {
    display: inline;
  }
  #nav-toggle:checked + .sidebar .lab-icone {
    display: inline;
  }

  #nav-toggle:checked + .sidebar .lab-icone2 {
    display: inline;
  }

  #nav-toggle:checked + .sidebar i {
    display: inline;
    margin-left: 40px;
    font-size: 13px;
  }

  #nav-toggle:checked + .sidebar .nav__dropdown-content {
    display: grid;
    row-gap: 0.1rem;
    padding: 0.2rem 1.5rem 0.2rem 1rem;
    cursor: pointer;
  }

  #nav-toggle:checked + .sidebar .side:hover .Legenda-side {
    display: none;
  }

  #nav-toggle:checked ~ .main-content {
    margin-left: 0px;
  }
  .search-wrapper {
    display: none;
  }

  .sidebar {
    left: -100% !important;
  }

  .main-content {
    width: 100%;
    margin-left: 0rem;
  }

  header {
    width: 100% !important;
    left: 0 !important;
  }

  #nav-toggle:checked + .sidebar {
    left: 0 !important;
    z-index: 100;
    width: 220px;
  }

  #nav-toggle:checked + .sidebar .sidebar-menu,
  #nav-toggle:checked + .sidebar li {
    text-align: left;
  }

  #nav-toggle:checked + .sidebar li a {
    padding-left: 1rem;
  }

  #nav-toggle:checked + .sidebar .sidebar-brand h2 span:last-child,
  #nav-toggle:checked + .sidebar li a span:last-child {
    display: inline;
  }

  #nav-toggle:checked ~ .main-content {
    padding-left: 0rem !important;
  }

  #nav-toggle:checked + .sidebar .side:hover .Legenda-side {
    display: none;
  }

  #nav-toggle:checked + .sidebar .side-dropdown:hover .Legenda-Dropdown {
    display: none;
  }

.main-title-agend{
    padding: 0.4rem 1.5rem;
  }

  .nome-list .span-nome{
font-size: 12px;
}

.nome-list .span-inf{
font-size: 12px;
}

.nome-list .span-inf2{
  margin-left: 10px;
font-size: 12px;
}

}

@media only screen and (max-width: 560px) {
  .cards {
    grid-template-columns: 100%;
  }
  .modal {
  margin-left: 10px;
  margin-right: 10px;
  }
  .container-conf .icone-conf-erro{
	  display: inline;
	}

  .container-conf{
	  max-height: auto;
	  }
	  
}

.div-titulo-pages-text{
    font-size: 20px;
    margin-left: 6px;
    margin-top: -2px;
}

.div-titulo-pages{
    width: auto;
    display: flex;
    margin-bottom: 15px;
}

/*-------------------------------------Campos padrão---------------------------------------*/
.input-box-ativos select {
        background-color: #fff;
        -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
        -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
        box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
        color: #12344D;
        font-size: 14px;
        font-weight: 500;
        box-sizing: border-box;
        display: inline-block;
        border-radius: 4px;
        border: 1px solid #92989e;
        -moz-transition: border linear 0.2s;
        -o-transition: border linear 0.2s;
        -webkit-transition: border linear 0.2s;
        transition: border linear 0.2s;
        width: 100%;
        border-width: 1px;
        height: 30px;
        margin-bottom: 4px;
    }
    
    .input-box-ativos select :hover {
        border: 1px solid #13293D;
    }
    
    .input-box-ativos select:focus {
        border: 2px solid #13293D;
    }


    .input-box-ativos input {
        background-color: #fff;
        -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
        -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
        box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
        color: #12344D;
        font-size: 14px;
        box-sizing: border-box;
        display: inline-block;
        padding: 6px 6px;
        border-radius: 4px;
        border: 1px solid #92989e;
        -moz-transition: border linear 0.2s;
        -o-transition: border linear 0.2s;
        -webkit-transition: border linear 0.2s;
        transition: border linear 0.2s;
        width: 100%;
        border-width: 1px;
        height: 30px;
        margin-bottom: 4px;
        color: rgb(0, 0, 0);
    }
    
    .input-box-ativos input:hover {
        border: 1px solid #13293D;
    }
    
    .input-box-ativos input:focus {
        border: 2px solid #13293D;
    }

  
    .input-box-ativos span.details {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
        color: #12344D;
    }
    
    .input-box-ativos {
        width: 100%;
    }
       
    .input-box-ativos .details-flex {
        display: flex;
    }
  
    .select-box-ativos-select span.details {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
        color: #12344D;
    }
    
    .select-box-ativos-select {
        width: 100%;
    }
       
    .select-box-ativos-select .details-flex {
        display: flex;
    }
 
   textarea {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #12344D;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding: 6px 6px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 100%;
    border-width: 1px;
    height: 60px;
    margin-bottom: 4px;
}

.textareaBox-ativo span.details {
    display: block;
    font-weight: 300;
    margin-bottom: 5px;
    color: #12344D;
}

.textareaBox-ativo textarea:hover {
    border: 1px solid #13293D;
}

.textareaBox-ativo textarea:focus {
    border: 2px solid #13293D;
}

.details-esc-ativo {
    font-weight: bold;
    color: #12344D;
    font-size: 15px;
    cursor: pointer;

}

.details-esc-ativo:hover {
    opacity: 0.8;
}

.details-flex-icon-ativo {
    margin-left: 10px;
    font-weight: bold;
    color: #12344D;
    font-size: 18px;
    margin-right: 2px;
}

.class-checkbox {
  margin-bottom: 10px;
  width: calc(80% / 1 - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;

}

.class-checkbox label {
  margin-left: 10px;
}

.lab-ativo {
  margin-left: 5px;
  margin-top: 3px;
  display: block;
  font-weight: 500;
  color: #12344D;
}
/*----------------------------------------------------------------------------*/


/*----------------------------------BOTÃO PRINCIPAL------------------------------------------*/
.Bt-button-cad {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 52px;
    border-radius: 8px;
    padding: 7px;
    margin-top: 20px;
    margin-bottom: 10px; 
    }
    
    .Bt-button-cad button {
    font-size: 15px;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    border: 1px solid rgb(102, 102, 102);
    margin-left: 17px;
    width: 100px;
   
    cursor: pointer;
    border-radius: 5px;
    }
    
    .Bt-button-cad button:hover {
    border: 1px solid #aaa7a7;
    }

    .icon-ok-btn{
      color: #0adb49;
    }

    .icon-cancelar-btn{
      color: #ff0000;
    }
/*----------------------------------------------------------------------------*/
.ContainerBody{
  width: 100%;
  display: flex;
  justify-content: space-between; 
}

.grid-container-principal{
  margin-top: 10px;
}

.container-modal-pag{
  padding: 20px;
}

@media print {
    html, body, #root {
        background-color: #ffffff !important; /* Define o fundo como branco */

    }
}

`;

