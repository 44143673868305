import React, { useState, useEffect } from "react";
import "./index.css";
import { FcBullish } from "react-icons/fc";
import { useNavigate } from 'react-router';

const Dashboard: React.FC = () => {

  const navigate = useNavigate();

  function voltar(){
    navigate('/task');
  }

  return (
    <>
  <div  style={{ paddingTop: "100px", textAlign: 'center'}}>
  <FcBullish className="icon-dash"/>
				<h1 className="lab-dash">Estamos consolidando os dados para exibição.</h1>
        <button type="submit" className="btn-enviar3" onClick={voltar}>Tela Inicial</button>
			</div>
    </>
  );
};

export default Dashboard;
