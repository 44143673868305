
import React, { useState, useEffect } from "react";
import Logo from '../../assets/LOGO.png';
import "./index.css";
import { FaBars, FaTools, FaHistory } from "react-icons/fa";
import { AiOutlineQrcode } from "react-icons/ai"; // Ícone para o QR Code
import { BsQrCodeScan } from "react-icons/bs";
import { AiOutlineClose, AiOutlineDashboard, AiOutlineLogout } from "react-icons/ai";
import { FaChartArea } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import QRCodeScanner from "../../components/Qrcode";
import { useNavigate } from 'react-router';
import  secureLocalStorage  from  "react-secure-storage";

const Header = () => {

  const navigate = useNavigate();
  
    const [menuOpen, setMenuOpen] = useState(false);
    const [logo, setLogo] = useState(null);

    useEffect(() => {

      const logoFromStorage = secureLocalStorage.getItem("lg");

      const image = new Image();
      image.src = logoFromStorage;
  
      image.onload = () => setLogo(logoFromStorage);
      image.onerror = () => setLogo(null);
  
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };

      const handleLogoff = () => {
        secureLocalStorage.removeItem('https')
        secureLocalStorage.removeItem('paramsEntidade')
        secureLocalStorage.removeItem('paramsQrcode')
        secureLocalStorage.removeItem('tpcm')
        secureLocalStorage.removeItem('acp')
        secureLocalStorage.removeItem('n')
        secureLocalStorage.removeItem('iag')
        
        
        navigate('/login');
      };
    
      const chamado = () => {
        navigate('/task');
      };


      const historico = () => {
        navigate('/history');
      };

      const dashboard = () => {
        navigate('/dashboard');
      };

  return (
   <header className="chamados-header">
        <img src={logo || Logo} alt="Mobcode Logo" className="chamados-logo" />
        <nav className={`chamados-menu ${menuOpen ? "active" : ""}`}>
          <a onClick={chamado} className='menu-cursor'>
            <FaTools className="menu-icon-left" /> CHAMADOS
          </a>
          <a onClick={historico}  className='menu-cursor'>
            <FaHistory className="menu-icon-left" /> HISTÓRICO
          </a>
          <a onClick={dashboard}  className='menu-cursor'>
            <FaChartArea className="menu-icon-left" /> DASHBOARD
          </a>
          <a onClick={handleLogoff} className='menu-cursor'>
            <IoMdExit className="menu-icon-left" /> SAIR
          </a>
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <AiOutlineClose /> : <FaBars />}
        </div>
      </header>
  );
};

export default Header;
