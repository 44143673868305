import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import PermissionDenied from "./PermissionDenied";
import InnerContent from "./InnerContent";
import NoPage from "../pages/NoPage";

import Login from "../pages/Login";
import Chamados from "../pages/Chamados";
import Historico from "../pages/Historico";
import Dashboard from "../pages/Dashboard";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Rotas Protegidas */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/" element={<Navigate replace to="task" />} />
            <Route path="task" element={<Chamados />} />
            <Route path="history" element={<Historico />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/* Rotas Públicas */}
        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Route>

        {/* Rota Não Protegida para Chamados */}
        <Route path="/:modo/:entidade/:qrcode" element={<Login />} />

        {/* Outras Rotas */}
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
