import React, { useEffect, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";

const QRCodeScanner = ({ modalOpen, onQrCodeDetected, onClose }) => {
  const [html5QrCode, setHtml5QrCode] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [currentCameraId, setCurrentCameraId] = useState(null);
  const [scannerInitialized, setScannerInitialized] = useState(false); // Flag para verificar se o scanner foi iniciado

  useEffect(() => {
    if (!modalOpen) {
      // Quando o modal é fechado, não faz nada se o scanner não foi inicializado
      if (scannerInitialized) {
        cleanupScanner();
      }
      return;
    }

    const qrScanner = new Html5Qrcode("qr-reader");
    setHtml5QrCode(qrScanner);

    // Obtém as câmeras disponíveis
    Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices.length > 0) {
          setCameras(devices);
          const backCamera = devices.find((device) =>
            device.label.toLowerCase().includes("back")
          );
          const initialCameraId = backCamera ? backCamera.id : devices[0].id;
          setCurrentCameraId(initialCameraId);

          // Inicia o scanner com a câmera inicial
          startScanner(qrScanner, initialCameraId);
        } else {
         // console.error("Nenhuma câmera encontrada.");
        }
      })
      .catch((error) => {
        //console.error("Erro ao obter câmeras:", error);
      });

    // Cleanup ao fechar o modal
    return () => {
      if (scannerInitialized) {
        cleanupScanner();
      }
    };
  }, [modalOpen, scannerInitialized]); // Agora depende de scannerInitialized também

  const startScanner = (qrScanner, cameraId) => {
    qrScanner
      .start(
        cameraId,
        { fps: 10, qrbox: { width: 250, height: 250 } },
        (decodedText) => {
         // console.log("QR Code detectado:", decodedText);
          cleanupScanner(); // Para o scanner e limpa os recursos
          onQrCodeDetected(decodedText); // Envia o valor ao pai
          onClose(); // Fecha o modal automaticamente
        },
        (errorMessage) => {
         // console.warn("Erro ao ler QR Code:", errorMessage);
        }
      )
      .then(() => {
        setScannerInitialized(true); // Marca que o scanner foi inicializado
      })
      .catch((error) => {
       // console.error("Erro ao iniciar o scanner:", error);
      });
  };
  

  const cleanupScanner = () => {
    if (html5QrCode) {
      html5QrCode
        .stop()
        .then(() => {
          html5QrCode.clear();
        })
        .catch((error) => console.error("Erro ao parar o scanner:", error));
    }
    const qrReaderElement = document.getElementById("qr-reader");
    if (qrReaderElement) {
      qrReaderElement.innerHTML = ""; // Limpa o conteúdo do leitor
    }
    setHtml5QrCode(null);
    setCurrentCameraId(null);
    setScannerInitialized(false); // Marca o scanner como não inicializado
  };

  const switchCamera = () => {
    if (!html5QrCode || cameras.length < 2) {
     // console.warn("Não há câmeras disponíveis para alternar.");
      return;
    }

    const currentIndex = cameras.findIndex(
      (camera) => camera.id === currentCameraId
    );
    const nextIndex = (currentIndex + 1) % cameras.length;
    const nextCameraId = cameras[nextIndex].id;

    html5QrCode
      .stop()
      .then(() => {
        setCurrentCameraId(nextCameraId);
        startScanner(html5QrCode, nextCameraId);
      })
      .catch((error) => {
        //console.error("Erro ao alternar câmera:", error);
      });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        id="qr-reader"
        style={{
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
          borderRadius: "8px",
        }}
      />
      {cameras.length > 1 && (
        <button
          onClick={switchCamera}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "5px",
            border: "none",
            backgroundColor: "#007bff",
            color: "#fff",
          }}
        >
          Alternar Câmera
        </button>
      )}
    </div>
  );
};

export default QRCodeScanner;
