import React, { useState, useEffect } from "react";
import "./index.css";
import Header from "../../components/Header";
import Select from 'react-select'
import ModalAlert from '../../components/ModalAlert/modal'
import { MdQrCodeScanner } from "react-icons/md";
import { useNavigate } from 'react-router';
import Card from "../../components/CardHistorico";
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import moment from 'moment';
import { FaRegCircleXmark } from "react-icons/fa6";
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import { FaCheckCircle } from "react-icons/fa";
import Loading from '../../components/Loading/index'

const Historico: React.FC = () => {

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [status, setStatus] = useState('');
  const [dataIni, setDataIni] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [optionsSituacao, setOptionsSituacao] = useState([])
  const [optionsChamados, setOptionsChamados] = useState([])
  const [loading, setLoading] = useState(false);
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [selectedData, setSelectedData] = useState<any>(null);
  const [resolved, setResolved] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  useEffect(() => {

    GetSituacao();
    carregarChamados();

  }, []);

  function limpar(event: React.FormEvent) {
    event.preventDefault(); // Impede a atualização da página
    setStatus('')
    setDataIni('')
    setDataFim('')
    carregarChamados()
  }

  function carregarChamados() {
    const _user = secureLocalStorage.getItem('tpcm');

    api.defaults.headers.common['Authorization'] = _user as '';

    let dados = [];

    let acesso: any

    if(secureLocalStorage.getItem('acp') === 'Administrador'){
      acesso = null
    }else{
      acesso = secureLocalStorage.getItem('iag')
    }

    dados.push({
      tsk_active: '1',
      tsk_id: '',
      tea_id: '',
      age_id: '',
      loc_id: '',
      ast_id: '',
      tsk_integrationid: '',
      stn_id: '',
      tsk_observation: '',
      tty_id: '',
      crt_id: '',
      pvt_id: '',
      pla_id: '',
      startdate: '',
      enddate: '',
      search: '',
      startdateUlt: '',
      enddateUlt: '',
      age_id_insert: acesso,
    });

    setLoading(true); // Inicia o loading

    api.get('task/buscartaskportal', {
      params: { data: dados }
    })
      .then((res) => {
        var data = res.data.data;
        setOptionsChamados(data);
        setLoading(false); // Finaliza o loading
      })
      .catch(function (e) {
        setLoading(false); // Finaliza o loading, mesmo se ocorrer erro
      });
  }

  function finalizarModal2() {
    setOpenModal(false)
  }

  function finalizarModal3() {
    window.location.reload();
  }


  // Função para validar as datas
  const validarDatas = (event: React.FormEvent) => {
    event.preventDefault(); // Impede a atualização da página

    if (status === '' && dataIni === '' && dataFim === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Por favor, selecione o que deseja filtrar.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (dataIni !== '' && dataFim === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data fim.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (dataIni !== '' && dataFim === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data fim.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (dataIni === '' && dataFim !== '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione a data inicial.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);
    } else if (dataIni !== '' && dataFim !== '') {

      const dataInicial = moment(dataIni, 'YYYY-MM-DD');
      const dataFinal = moment(dataFim, 'YYYY-MM-DD');
      // Calcula a diferença em meses
      const diferencaEmMeses = dataFinal.diff(dataInicial, 'months', true);

      // Verificar se dataFim não é menor que dataIni
      if (dataFinal.isBefore(dataInicial)) {

        setQualAlert(
          <div className='div-alert'>
            <FaRegCircleXmark className="ControleIconAlertcancel" />
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que a data inicial.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>
        );

        setOpenModal(true);
      } else if (diferencaEmMeses > 3) {

        setQualAlert(
          <div className='div-alert'>
            <FaRegCircleXmark className="ControleIconAlertcancel" />
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">O intervalo entre as datas não pode ser maior que 3 meses.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
          </div>
        );

        setOpenModal(true);

      } else {

        const _user = secureLocalStorage.getItem('tpcm')

        api.defaults.headers.common['Authorization'] = _user as ''

        let dados = [];

        let acesso: any

        if(secureLocalStorage.getItem('acp') === 'Administrador'){
          acesso = null
        }else{
          acesso = secureLocalStorage.getItem('iag')
        }	
    

        dados.push({
          tsk_active: '1',
          tsk_id: '',
          tea_id: '',
          age_id: '',
          loc_id: '',
          ast_id: '',
          tsk_integrationid: '',
          stn_id: status,
          tsk_observation: '',
          tty_id: '',
          crt_id: '',
          pvt_id: '',
          pla_id: '',
          startdate: dataIni,
          enddate: dataFim,
          search: '',
          startdateUlt: '',
          enddateUlt: '',
          age_id_insert: acesso,
        })

        setLoading(true);

        api.get('task/buscartaskportal', {
          params: {
            data: dados
          }
        }).then((res) => {

          var data = res.data.data;

          setOptionsChamados(data)
          setLoading(false); // Finaliza o loading, mesmo se ocorrer erro
        })
          .catch(function (e) {
            setLoading(false); // Finaliza o loading, mesmo se ocorrer erro
          });

      }

    } else if (dataIni === '' && dataFim === '' && status !== '') {
      const _user = secureLocalStorage.getItem('tpcm');

      api.defaults.headers.common['Authorization'] = _user as '';

      let dados = [];

      let acesso: any

      if(secureLocalStorage.getItem('acp') === 'Administrador'){
        acesso = null
      }else{
        acesso = secureLocalStorage.getItem('iag')
      }	

      dados.push({
        tsk_active: '1',
        tsk_id: '',
        tea_id: '',
        age_id: '',
        loc_id: '',
        ast_id: '',
        tsk_integrationid: '',
        stn_id: status,
        tsk_observation: '',
        tty_id: '',
        crt_id: '',
        pvt_id: '',
        pla_id: '',
        startdate: '',
        enddate: '',
        search: '',
        startdateUlt: '',
        enddateUlt: '',
        age_id_insert: acesso,
      });

      setLoading(true); // Inicia o loading

      api.get('task/buscartaskportal', {
        params: { data: dados }
      })
        .then((res) => {
          var data = res.data.data;
          setOptionsChamados(data);
          setLoading(false); // Finaliza o loading
        })
        .catch(function (e) {
          setLoading(false); // Finaliza o loading, mesmo se ocorrer erro
        });
    }
  };


  async function GetSituacao() {
    let ret: AxiosResponse;
    let valor: any;
    try {
      ret = await api.get("tasksituation/listar")
      if (ret.data.status === 200) {
        valor = ret.data.data;
        const arr = [] as any;
        let result = valor;

        const sit = valor.filter(
          (e: any) => {
            return (e.stn_id !== 80);
          }
        );

        sit.map((user: any) => {
          return arr.push({ value: String(user.stn_id), label: user.stn_description });
        });

        setOptionsSituacao(arr)

      }
    } catch (e) {
      setOptionsSituacao([])
    }
  }

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: '#fff',
      minHeight: '35px',
      height: '35px',
      border: state.isFocused ? '1px solid #000000' : '1px solid #92989e', // Ajusta borda para 1px no foco
      boxShadow: 'none', // Remove qualquer sombra adicional no foco
      '&:hover': {
        borderColor: state.isFocused ? '#000000' : 'grey', // Cor da borda no hover
      },
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
      padding: '0 6px',

    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
      fontSize: 14,

    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
    }),
  };

async function abrirModal(data: any) {

    setResolved(null);
    setRating(0);
    setComment("");
    setSelectedData(data)

    if(data.stn_id === 50){
      await GetAvaliacao(data.tsk_id)
    }
 


    setOpenModalIncluirNovo(true)

  }



  async function GetAvaliacao(id: any) {

    let ret: AxiosResponse;
    let valor: any;

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    try {
      ret = await api.get("approvetask/listar", {
        params: {
            id: id
        }
    })
      if (ret.data.status === 200) {
        valor = ret.data.data[0];

        setResolved(String(valor.apt_sortedout));
        setRating(valor.apt_classification);
        setComment(valor.apt_observation);

        setOpenModal(false)

      }else{
        setOpenModal(false)
      }
    } catch (e) {
      setOpenModal(false)
    }
  }


  const formatDateTime = (isoString: string): string => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Janeiro é 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };


  async function confirmarClassificacao(event: React.FormEvent) {
    event.preventDefault(); // Impede a atualização da página

    setQualAlert(<div className='div-alert'><Loading /></div>)
    setOpenModal(true)

    if (resolved === null) {

      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Selecione se sua solicitação foi atendida com sucesso.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);

    } else if (rating === 0) {

      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Classifique a qualidade do atendimento de 1 a 5.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );

      setOpenModal(true);

    } else {

      const dados = [{
        tsk_id: selectedData?.tsk_id,
        apt_sortedout: resolved,
        apt_classification: rating,
        apt_observation: comment,
        age_id_insert: secureLocalStorage.getItem('iag'),
      }]

      const _user = secureLocalStorage.getItem('tpcm');

      api.defaults.headers.common['Authorization'] = _user as ''

      let ret: AxiosResponse;

      try {
        ret = await api.post("approvetask/insert", {
          data: dados,
        });
        if (ret.data.status === 200) {


          setQualAlert(<div className='div-alert'>
            <FaCheckCircle className="ControleIconAlert" />
            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
            <div className='div-info-alert'><span className="info-alert">Classificação concluída com sucesso.</span></div>
            <div className='btn-alert' onClick={finalizarModal3}><span className="btn-alert-lab">OK</span></div>
          </div>)
          setOpenModal(true)
        }

      } catch (e: any) {

        setQualAlert(<div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel" />
          <div className='div-info-alert'><span className="info-alert">Não é possível criar a Tarefa.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
        setOpenModal(true)
      }
    }


  }

  return (
    <>
      <div className="chamados-container">
        <Header />
        <div className="chamados-main">



          <div className="chamados-card2">

            <div className="card-tab">
              <div className="header-historico">
                <h1>Últimos Chamados</h1>
              </div>

              <div className="busca-historico">
                <form className="chamados-form">
                  <div className="form-group">
                    <label>Status: </label>
                    <Select
                      maxMenuHeight={140}
                      placeholder={'Selecione...'}
                      options={optionsSituacao}
                      value={optionsSituacao.filter(function (option: any) {
                        return option.value === status;
                      })}
                      onChange={(options: any) =>
                        !options ? setStatus("") : setStatus(options.value)
                      }
                      styles={customStyles}
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>

                  <div className="container-cad3">
                    <div id="content1">
                      <div className="form-group">
                        <label>Início: </label>
                        <input type="date"
                          className="inp-date-date"
                          onChange={(e) => setDataIni(e.target.value)}
                          value={dataIni} />
                      </div>
                    </div>
                    <div id="content2">
                      <div className="form-group">
                        <label>Fim: </label>
                        <input type="date"
                          className="inp-date-date"
                          onChange={(e) => setDataFim(e.target.value)}
                          value={dataFim} />
                      </div>
                    </div>
                  </div>

                  <div className="btn-container">
                    <button type="submit" className="btn-enviar2" onClick={validarDatas}>Filtrar</button>
                    <button type="submit" className="btn-enviar3" onClick={limpar}>Limpar</button>
                  </div>

                </form>

                <div className="listagem-principal">
                  <div className="listagem-historico">
                    {loading ? (
                      <div className="loading-spinner"></div> // A bolinha rodando
                    ) : (
                      optionsChamados.map((chamado: any, index) => (
                        <Card
                          key={index}
                          osNumber={chamado.tsk_id || 'N/A'}
                          openingDate={chamado.tsk_scheduleinitialdatehour || 'N/A'}
                          department={chamado.loc_description || chamado.ast_description}
                          situacao={chamado.stn_id || 'N/A'}
                          approval={chamado.tsk_approval}
                          abrirModal={abrirModal}
                          obj={chamado}
                        />
                      ))
                    )}
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">RESUMO DA SOLICITAÇÃO</h1>
            <div onClick={fecharOpenModalIncluirNovo}>
              <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo}></button>
            </div>
          </div>

          <div className='scrol-modal'>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">ID: </p>
              <p className="resumo-inf-atendimento-link">{selectedData?.tsk_id}</p>
            </div>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">Local / Ativo: </p>
              <p>{selectedData?.loc_description || selectedData?.ast_description} </p>
            </div>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">Status: </p>
              <p>{selectedData?.statusDescription} </p>
            </div>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">Data de Abertura: </p>
              <p>{formatDateTime(selectedData?.tsk_scheduleinitialdatehour)}</p>
            </div>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">Solicitante: </p>
              <p>{selectedData?.tsk_requester} </p>
            </div>

            <div className="resumo-inf">
              <p className="resumo-inf-atendimento">Descrição da Solicitação: </p>
              <p> {selectedData?.tsk_observation} </p>
            </div>

            {selectedData?.stn_id === 50 ? (
             <form className="feedback-form">
             {/* Pergunta sobre resolução */}
             <h3 className="form-heading">A solicitação foi resolvida?</h3>
             <div className="radio-group">
               <label className="radio-label">
                 <input
                   type="radio"
                   name="resolved"
                   value="sim"
                   onChange={() => setResolved('1')}
                   checked={resolved === "1"}
                   disabled={selectedData?.tsk_approval === 1} // Desabilitar se tsk_approval for 1
                 />
                 SIM
               </label>
               <label className="radio-label">
                 <input
                   type="radio"
                   name="resolved"
                   value="nao"
                   onChange={() => setResolved("0")}
                   checked={resolved === "0"}
                   disabled={selectedData?.tsk_approval === 1} // Desabilitar se tsk_approval for 1
                 />
                 NÃO
               </label>
             </div>
           
             {/* Avaliação por estrelas */}
             <h3 className="form-heading2">O que você achou do nosso atendimento?</h3>
             <div className="stars-container">
               {[1, 2, 3, 4, 5].map((star) => (
                 <span
                   key={star}
                   onClick={() => selectedData?.tsk_approval !== 1 && setRating(star)} // Bloquear clique se tsk_approval for 1
                   className={`star ${star <= rating ? "active" : ""} ${selectedData?.tsk_approval === 1 ? "disabled" : ""}`} // Estilo opcional para indicar bloqueio
                 >
                   ★
                 </span>
               ))}
             </div>
           
             {/* Comentário */}
             <textarea
               className="form-textarea"
               placeholder="Deixar Seu Comentário"
               value={comment}
               onChange={(e) => setComment(e.target.value)}
               disabled={selectedData?.tsk_approval === 1} // Desabilitar se tsk_approval for 1
             />
           
             {/* Botão de envio */}
             <div>
               <button
                 type="submit"
                 className="btn-enviar2"
                 onClick={confirmarClassificacao}
                 disabled={selectedData?.tsk_approval === 1} // Desabilitar se tsk_approval for 1
               >
                 Confirmar
               </button>
             </div>
           </form>
           
            ) : null}
          </div>

        </>
      </ModalIncluirNovo>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </>
  );
};

export default Historico;
