
import React, { useState, useEffect } from 'react'
import './Login.css';
import Logo from '../../assets/LOGO.png';
import { FaUser, FaLock, FaBuilding } from 'react-icons/fa';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import api from '../../Services/api'
import axios from 'axios';
import Loading from '../../components/Loading/index'
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../../components/ModalAlert/modal'
import { FaRegCircleXmark } from "react-icons/fa6";
import { useParams } from 'react-router-dom';

const Login: React.FC = () => {

  let {entidade, qrcode } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [company, setCompany] = useState("")
  const [login, setLogin] = useState("")
  const [senha, setSenha] = useState("")
  const [https, setHttps] = useState("")
  const [show, setShow] = useState(false)
  const [sucesso, setSucesso] = useState('');
  const [openModal, setOpenModal] = useState(false)
  const [qualAlert, setQualAlert] = useState(<></>)
  const [disabledAmbiente, setDisabledAmbiente] = useState(true)

  useEffect(() => {

    const ambiente = ''
    const currentUrl = window.location.href;
    const hostname = new URL(currentUrl).hostname;
  
    setHttps(currentUrl)
    // Verifica se o hostname contém "westinportodegalinhas"
    if (hostname.includes("westinportodegalinhas")) {

      setCompany('THE WESTIN')

      setDisabledAmbiente(true)
    } else {
      setCompany('')
      setDisabledAmbiente(false)

    }
 
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  function finalizarModal2() {
    setOpenModal(false)
  }

  async function ValidarLogin(event: React.FormEvent) {
    event.preventDefault(); // Impede a atualização da página
  
    if (company === '' || login === '' || senha === '') {
      setQualAlert(
        <div className='div-alert'>
          <FaRegCircleXmark className="ControleIconAlertcancel"/>
          <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
          <div className='div-info-alert'><span className="info-alert">Por favor, preencha todos os campos.</span></div>
          <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>
      );
  
      setOpenModal(true);
    } else {
      
      setQualAlert(<div className='div-alert'><Loading /></div>)
      setOpenModal(true)

      axios.get('https://tudoapi.mobcode.com.br:3000/ambientes/validar', {
         params: {
            ambiente: company
         }
      })
         .then(async function (response) {


            let endPoint = response.data.data[0].amb_endpointweb

            axios.post(endPoint + 'user/loginportal', {
               company: company,
               login: login,
               senha: senha
             })
               .then(async function (response) {


                  secureLocalStorage.setItem('lg', response.data.data.user[0].etp_logotipo);
                  secureLocalStorage.setItem('endPoint', endPoint);
                  secureLocalStorage.setItem('tpcm', response.data.userToken);
                  secureLocalStorage.setItem('acp', response.data.data.user[0].acp_integrationid);
                  secureLocalStorage.setItem('n', response.data.data.user[0].age_name);
                  secureLocalStorage.setItem('iag', response.data.data.user[0].age_id);
                  secureLocalStorage.setItem('cy', response.data.data.user[0].etp_id);
                  secureLocalStorage.setItem('tp', response.data.data.user[0].etp_name);

                  api.defaults.headers.common['Authorization'] = response.data.userToken as ''

              if(entidade !== undefined && entidade !== null){
                secureLocalStorage.setItem('paramsEntidade', entidade);
                
              }
              
              if(qrcode !== undefined && qrcode !== null){
                secureLocalStorage.setItem('paramsQrcode', qrcode);
                secureLocalStorage.setItem('https', String(https));
                
              }
               

                  api.defaults.headers.common['Authorization'] = response.data.userToken as ''

                   // Armazenando no secureLocalStorage
                   secureLocalStorage.setItem("pmspc", JSON.stringify(response.data.data.perfilAcesso));
                
                  // if(login === 'MASTER'){
                    navigate('/task');
                  // }
                 

               })
               .catch(function (error) {

                  if (error.response === undefined) {

                     setQualAlert(<div className='div-alert'>
                        <FaRegCircleXmark className="ControleIconAlertcancel"/>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                     </div>)


                  } else if (error.response.status === 401) {

                     setQualAlert(<div className='div-alert'>
                        <FaRegCircleXmark className="ControleIconAlertcancel"/>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">O usuário não foi encontrado ou não possui permissão para acessar este recurso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                     </div>)

                  } else {

                     setQualAlert(<div className='div-alert'>
                        <FaRegCircleXmark className="ControleIconAlertcancel"/>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                     </div>)

                  }

               })

         })
         .catch(async function (error) {


            if (error.response === undefined) {

               setQualAlert(<div className='div-alert'>
                  <FaRegCircleXmark className="ControleIconAlertcancel"/>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Desculpe, não conseguimos conectar ao servidor no momento.</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
               </div>)


            } else if (error.response.status === 401) {

               setQualAlert(<div className='div-alert'>
                  <FaRegCircleXmark className="ControleIconAlertcancel"/>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Ambiente não encontrado!</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
               </div>)

            } else {

               setQualAlert(<div className='div-alert'>
                  <FaRegCircleXmark className="ControleIconAlertcancel"/>
                  <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                  <div className='div-info-alert'><span className="info-alert">Não foi possivel realizar o login!</span></div>
                  <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
               </div>)

            }


         });



    }
  }
  


  return (
    <div className="login-container">
      <div className="login-card">
        <img src={Logo} alt="Mobcode Logo" className="login-logo" />
        <form className="login-form">

          <div className="input-group">
            <FaBuilding className="input-icon" />
            <input className="login-input"
              placeholder="AMBIENTE"
              disabled={disabledAmbiente}
              value={company}
              onChange={e => setCompany(e.target.value.toUpperCase())} />
          </div>

          <div className="input-group">
            <FaUser className="input-icon" />
            <input
              className="login-input"
              placeholder="USUÁRIO"
              value={login}
              onChange={e => setLogin(e.target.value.toUpperCase())} />
          </div>

          <div className="input-group">
            <FaLock className="input-icon" />
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="SENHA"
              className="login-input"
              value={senha}
              onChange={e => setSenha(e.target.value)}
            />
            {showPassword ? (
              <AiOutlineEye className="input-icon-right" onClick={togglePasswordVisibility} />
            ) : (
              <AiOutlineEyeInvisible className="input-icon-right" onClick={togglePasswordVisibility} />
            )}
          </div>
          <button type="submit" className="login-button" onClick={ValidarLogin}>Entrar</button>


          <span className="title-senha">Esqueceu sua senha?</span>
  

        </form>
      </div>

      <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
        <div className='container-modal-alert'>
          {qualAlert}
        </div>
      </ModalAlert>

    </div>
  );
};

export default Login;
