
import React from 'react'
import './index.css'
import { useNavigate } from 'react-router'

function NoPage() {

    const navigate = useNavigate();

	function Voltar() {
       
        navigate('/dashboard');
     
    }
    return (
        <>

      
            <div className='containerPrincipalpage'>
                <i className="fa fa-exclamation-triangle icon404" aria-hidden="true"></i>
                 <h1 className="titlepage1">PÁGINA NÃO ENCONTRADA</h1> 

                 <h1 className="titlepage2">O conteúdo que você solicitou não foi encontrado em nossos servidores.</h1> 
                
                 

            </div>
          
        
        </>
    );

}

export default NoPage;